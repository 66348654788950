<template>
    <div class="mt-3">
        <label class="pb-1">{{$t('Use fulltext index (search more fields)')}}</label>
        <!--<div class="form-check form-check-inline small" v-for="item in filterItem.searchFunction.options">
            <input class="form-check-input" type="radio" :name="id+'radio'" :id="id+item.name" :value="item.name" v-model="filterItem.searchFunction.currentOption">
            <label class="form-check-label" :for="id+item.name">{{ item.title}}</label>
        </div>-->
        <input type="search" ref="input" 
            v-model="filterItem.searchFunction.value" v-focus 
            :placeholder="filterItem.searchFunction.searchForLabel" class="form-control form-control-sm rounded-0"
            @keyup.enter="apply()"/>
    </div>
</template>

<script setup>
import { onMounted,ref } from 'vue';
const id = 'filltext_' + window.crypto.randomUUID();
const props = defineProps(['filterItem','filterObject','dropdown']);
const input = ref(null);

onMounted(()=>{
    window.setTimeout(()=>{
        input.value.focus();
    },10);
});

const apply = () =>{
    if(input.value.value.length > 3){
        props.filterObject.apply();
        props.dropdown.close();
    }
}
</script>